import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {AboutComponent} from './pages/about/about.component';
import {ServicesComponent} from './pages/services/services.component';
import {ContactusComponent} from "./pages/contactus/contactus.component";
import {ProductsComponent} from "./pages/products/products.component";
import {WorksComponent} from "./pages/works/works.component";
import {TermsComponent} from "./pages/terms/terms.component";
import {ServicesDetailsComponent} from "./pages/services-details/services-details.component";

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'service-details', component: ServicesDetailsComponent },
    { path: 'contactus', component: ContactusComponent },
    { path: 'products', component: ProductsComponent },
    { path: 'works', component: WorksComponent },
    { path: 'terms', component: TermsComponent },
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
