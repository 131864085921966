import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    constructor() {
    }
    ngOnInit(): void {
        window.scroll(0, 0);
    }
}
