import {Component, OnInit} from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    services: OwlOptions = {
        loop: true,
        mouseDrag: true,
        margin: 15,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 2
            },
            940: {
                items: 4
            }
        },
        nav: true
    }
    weserve: OwlOptions = {
        loop: true,
        mouseDrag: true,
        margin: 15,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 6
            }
        },
        nav: true
    }
    constructor() {
    }
    experience = {
        slidesToShow: 5.5,
        slidesToScroll: 1,
        nextArrow: '<div class=\'nav-btn next-slide\'></div>',
        prevArrow: '<div class=\'nav-btn prev-slide\'></div>',
        arrows: true,
        autoplay: true,
        enabled: true,
        autoplaySpeed: 3000,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6.5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    slideClient = {
        slidesToShow: 3,
        slidesToScroll: 4,
        nextArrow: '<div class=\'nav-btn next-slide\'></div>',
        prevArrow: '<div class=\'nav-btn prev-slide\'></div>',
        arrows: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    slickInit(e) {
        console.log('slick initialized');
    }

    breakpoint(e) {
        console.log('breakpoint');
    }
    ngOnInit(): void {
        window.scroll(0, 0);
    }
}
