import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './pages/home/home.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import {AboutComponent} from './pages/about/about.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {CarouselModule} from "ngx-owl-carousel-o";
import {ServicesComponent} from "./pages/services/services.component";
import {ContactusComponent} from "./pages/contactus/contactus.component";
import {ProductsComponent} from "./pages/products/products.component";
import {WorksComponent} from "./pages/works/works.component";
import {TermsComponent} from "./pages/terms/terms.component";
import {ServicesDetailsComponent} from "./pages/services-details/services-details.component";
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AboutComponent,
        ServicesComponent,
        ContactusComponent,
        ProductsComponent,
        ServicesDetailsComponent,
        WorksComponent,
        TermsComponent,
        HeaderComponent,
        FooterComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SlickCarouselModule,
        BrowserAnimationsModule,
        FormsModule,
        NgSelectModule,
        NgbDatepickerModule,
        CarouselModule,
        NgbTabsetModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
