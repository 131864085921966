import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-works',
    templateUrl: './works.component.html',
    styleUrls: ['./works.component.scss']
})
export class WorksComponent implements OnInit {
    constructor() {
    }
    ngOnInit(): void {
        window.scroll(0, 0);
    }
}
