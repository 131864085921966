import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
    constructor() {
    }
    ngOnInit(): void {
        window.scroll(0, 0);
    }
}
